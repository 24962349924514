<template>
<div class="nothing_here">
<!--    <h2>Nothing here yet <br>Go Explore!</h2>-->
    <div class="nothing_here_image"></div>
</div>
</template>

<script>
export default {
    name: "nothingHereYet",
}
</script>

<style lang="scss">


</style>